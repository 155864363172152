/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "ventilatie/ventilatie-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "ventilatie/ventilatie-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(
      relativePath: { eq: "ventilatie/ventilatie-4.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `E S I - Mechanisch ventilatiesysteem`,
  textWithLink: () => (
    <>
      <p>
        Een mechanisch MV ventilatiesysteem is een ventilatiesysteem waarbij een
        woonhuisventilator is geplaatst voor de afzuiging van vervuilde lucht
        uit de woning. De afvoer van vervuilde ventilatielucht geschiedt via
        ventilatiekanalen vanuit keuken, badkamer en/of toilet, via de
        ventilator naar buiten.
      </p>
      <p>
        De toevoer van verse buitenlucht vind plaats middels ingebouwde
        ventilatieroosters in de ramen.
      </p>
    </>
  ),
};

const textBottom = {
  header: `E S I biedt top kwaliteit`,
  textWithLink: () => (
    <>
      <p>
        E S I - Installatietechniek B.V. werkt met de meest voorkomende
        fabrikanten en kan daardoor altijd een passende installatie aanbieden
        voor een comfortabele luchtkwaliteit. Nieuwsgierig?
      </p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0642090304'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
      <AniLink fade to='/ventilatie'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x ml-md-4x'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const Mechanische = ({ data, path, location }) => {
  const seo = {
    title: `Mechanische Ventilatie`,
    description: `E S I biedt een mechanisch MV ventilatiesysteem ventilatiesysteem aan waarbij een woonhuisventilator is geplaatst voor de afzuiging van vervuilde lucht uit de woning.`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Diverse mogelijkheden',
    textWithLink: () => (
      <>
        <p>
          Tegenwoordig zijn er verschillende uitvoeringen van deze mechanische
          ventilatiesystemen. Zo is het mogelijk meerdere draadloze schakelaars
          toe te passen, of te regelen op vocht, CO2 en aan/afwezigheid.
        </p>
      </>
    ),
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Voor al uw mechanische ventilatie (MV)`,
    pitchLine: `Laat uw MV over aan onze professionals.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        textBottom={textBottom}
      />
    </>
  );
};

Mechanische.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Mechanische;
